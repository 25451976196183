<template>
  <nav class="dn">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
  </nav>
  <router-view/>
</template>

<style lang="less">
@import "../src/styles/main.less";
</style>
