import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/Policy.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('../views/Technology.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/sup',
    name: 'sup',
    component: () => import('../views/Sup.vue')
  },
  {
    path: '/sec',
    name: 'sec',
    component: () => import('../views/Sec.vue')
  },
  {
    path: '/coin',
    name: 'coin',
    component: () => import('../views/Coin.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
