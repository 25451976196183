<template>
  <swiper :modules="modules" :breakpoints="{ 320: { slidesPerView: 1 }, 768: { slidesPerView: 1, spaceBetween: 20 }, 1201: { slidesPerView: 1, } }" :slides-per-view="1" :space-between="0"
    :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow' }" @swiper="onSwiper" @slideChange="onSlideChange">

    <div class="sliderew__buts">
      <div class="swiper-button-prev prevArrow">


        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.3" transform="matrix(-1 0 0 1 60 0.395508)" stroke="url(#paint0_linear_303_2306)"
            stroke-width="1.4" />
          <path d="M41 30.8955H19M19 30.8955L24.1163 26.3955M19 30.8955L24.1163 35.3955" stroke="#5667B4"
            stroke-width="2" />
          <defs>
            <linearGradient id="paint0_linear_303_2306" x1="0" y1="0" x2="64.4683" y2="5.26272"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#6FF3F3" />
              <stop offset="1" stop-color="#7384F7" />
            </linearGradient>
          </defs>
        </svg>



      </div>
      <!-- /.swiper-button-prev -->
      <div class="swiper-button-next nextArrow">

        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.3" transform="matrix(-1 0 0 1 60 0.395508)" stroke="url(#paint0_linear_303_2306)"
            stroke-width="1.4" />
          <path d="M41 30.8955H19M19 30.8955L24.1163 26.3955M19 30.8955L24.1163 35.3955" stroke="#5667B4"
            stroke-width="2" />
          <defs>
            <linearGradient id="paint0_linear_303_2306" x1="0" y1="0" x2="64.4683" y2="5.26272"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#6FF3F3" />
              <stop offset="1" stop-color="#7384F7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <!-- /.swiper-button-next -->
    </div>
    <!-- /.sliderew__buts -->
    <swiper-slide>
      <div class="rew">
        <div class="sliderew">
          <div class="sliderew__title">Hello future Zorters 👋</div>
          <!-- /.sliderew__title -->
          <div class="sliderew__txt">I have been using Zort's Automation Al software to trade for me. Their
            customer service is next to none, always there helping and answering any questions you have along
            the journey. I have zero worry going into a big trade while using Zort. Zort's software is near
            perfection where it sells if the market dumps and you loose little to none vs. if you were trading
            by hand. Blown away by what I have started and can't wait to continue the great journey along with
            the great Zort community!</div>
          <!-- /.sliderew__txt -->
          <div class="sliderew__name bluerik">— Noah</div>
          <!-- /.sliderew__name -->
        </div>
        <!-- /.sliderew -->
      </div>
      <!-- /.rew -->
    </swiper-slide>
    <swiper-slide>
      <div class="rew">
        <div class="sliderew">
          <div class="sliderew__title">Like a magic..</div>
          <!-- /.sliderew__title -->
          <div class="sliderew__txt">So far I've had really good results. It went to almost 100% cash at around 52K right before BTC tanked to 40K. I wrote it off as luck or coincidence back then. But I'm starting to believe that it actually, somehow, knows what it's doing.</div>
          <!-- /.sliderew__txt -->
          <div class="sliderew__name bluerik">— Zort User</div>
          <!-- /.sliderew__name -->
        </div>
        <!-- /.sliderew -->
      </div>
      <!-- /.rew -->
    </swiper-slide>
    <swiper-slide>
      <div class="rew">
        <div class="sliderew">
          <div class="sliderew__title">Impressive</div>
          <!-- /.sliderew__title -->
          <div class="sliderew__txt">I'm up 18% in a month 👌🏼 impressive. 
          And just as impressive that the AI has actually stopped buying now.</div>
          <!-- /.sliderew__txt -->
          <div class="sliderew__name bluerik">— Daniel</div>
          <!-- /.sliderew__name -->
        </div>
        <!-- /.sliderew -->
      </div>
      <!-- /.rew -->
    </swiper-slide>
    <swiper-slide>
      <div class="rew">
        <div class="sliderew">
          <div class="sliderew__title">In love with Zort ❤️</div>
          <!-- /.sliderew__title -->
          <div class="sliderew__txt">Zort made me $500 while I was on a 3-day camping trip! Normally I would be scared to be away from the charts for that amount of time and would have exited the market while I was away. Thanks Zort for keeping me in the game even when I'm off the grid! 💪</div>
          <!-- /.sliderew__txt -->
          <div class="sliderew__name bluerik">— DJ</div>
          <!-- /.sliderew__name -->
        </div>
        <!-- /.sliderew -->
      </div>
      <!-- /.rew -->
    </swiper-slide>
  </swiper>
</template>

<script>
// import Swiper core and required modules
import { Navigation, A11y, EffectFade } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// Import Swiper styles
export default {
  name: 'Slider1',

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, A11y, EffectFade],
    };
  },
};

</script>